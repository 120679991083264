// import router from '@/router';
import store from "@/store";
import axios from "axios";
import { app_types } from "./constants/role";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVICE_URL,
    withCredentials: true,
});

// Request interceptor for authentication
axiosInstance.interceptors.request.use(
    (req) => {
        // Add your authentication logic here
        const token = localStorage.getItem("accessToken");
        if (token) {
            req.headers.Authorization = token; // Use "Authorization"
        }
        req.headers['appType'] = app_types.Broker;
        return req;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// Response interceptor for error handling
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const {
            response: { status, data },
        } = error;

        if (
            (status == 401 &&
            (   data.message == "USER BLOCKED" ||
                data.message == "NO SESSION" ||
                data.message == "EXPIRED" || 
                data.message == "INVALID TOKEN" ||
                data.message == "UNAUTHORIZED"
            )) || (status == 404 && data.message == "INVALID TOKEN")
        ) {
            store.dispatch("authStore/clearUserSession");
            return Promise.reject(data.message);
        } else if(status == 401 && data.type == "account_status") {
            store.commit("setSnackbarMessage", data.message);
            store.commit("toggleSnackbar");
            store.dispatch("authStore/clearUserSession");
            return Promise.reject(data.message);
        } else if(status == 403 && data.type === 'account_status') {
            // TODO: This check can moved at a store level if needed at multiple places.
            if(store.state?.snackbar?.isSnackBarVisible) {
                store.commit("toggleSnackbar");
            }
            store.commit("setSnackbarMessage", data.message);
            store.commit("toggleSnackbar");
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);

export default axiosInstance;
