import { apiPath } from "@/config/apiPath";
import router from "@/router";
import axiosInstance from "@/util/axios";
import { jwtDecode } from "jwt-decode";
import { app_types } from "@/util/constants/role";

const state = {
  currentUser: null,
  accessToken: null,
  refreshToken: null,
  userLoggedIn: false,
  currentPermission: null,
  currentBrand: null,
  currentSubscription: null,
};

const getters = {
  getUser: (state) => state.currentUser,
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
};

const mutations = {
  setUser: (state, payload) => {
    state.currentUser = payload;
    state.currentPermission = payload.permissions;
    state.currentBrand = payload.company;
    localStorage.setItem("userInfo", JSON.stringify(payload));
  },
  setAccessToken: (state, payload) => {
    state.accessToken = payload;
    localStorage.setItem("accessToken", payload);
  },
  changeUserState: (state, payload) => (state.userLoggedIn = payload),
  setCurrentSubscription: (state, payload) =>
    (state.currentSubscription = payload.subscription),
  clearAll: (state) => {
    state.currentUser = null;
    state.accessToken = null;
    state.refreshToken = null;
    state.userLoggedIn = false;
    localStorage.clear();
  },
  doNothing: (state) => state,
  setCurrentUserImages: (state, payload) => {
    if(state.currentUser && state.currentUser.images){
      state.currentUser.images = payload;
    }
  },
  setAddharVerifed: (state, payload) => {
    if(state.currentUser && Object.prototype.hasOwnProperty.call(state.currentUser, 'aadharVerified')){
      state.currentUser.aadharVerified = payload;
    }
  }
};

const actions = {
  login({ commit }, payload) {
    payload = {
      ...payload,
      appType: app_types.Broker,
    }
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.login, payload)
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data, "res dena");
            // redirect to the home page
            if(res.data.is_user_verified === false) {
              resolve(res);
            } else if (res.data.user.app_type == "Broker") {
              commit("setUser", res.data.user);
              commit("setAccessToken", res.data.accessToken);
              commit("changeUserState", true);
              commit("setCurrentSubscription", jwtDecode(res.data.accessToken));
              // localStorage.setItem("accessToken", res.data.accessToken);
              // localStorage.setItem("userInfo", JSON.stringify(res.data.user));
              resolve(res.data);
              router.push("/");
            } else {
              commit("clearAll");
              if (window.location.pathname != "/login") {
                return router.push({ name: "login-page" });
              }
              reject({
                success: false,
                message: "Account does not exist",
              });
            }
          }
        })
        .catch((err) => {
          reject(err?.response?.data);
          console.log("err in login", err);
        });
    });
  },

  refreshToken({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(apiPath.auth.refreshToken, {
          withCredentials: true,
        })
        .then((res) => {
          console.log("token ref", res);
          if (res.status == 200) {
            commit("setAccessToken", res.data.accessToken);
            commit("setCurrentSubscription", jwtDecode(res.data.accessToken));
            localStorage.setItem("accessToken", res.data.accessToken);
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err?.response?.data);
          console.log(err, "err in refresh");
        });
    });
  },

  logout({ dispatch }) {
    console.log("calling logout");
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(apiPath.auth.logout)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        })
        .finally(() => {
          dispatch('clearUserSession');
        });
    });
  },

  // description: Get current User Data
  getCurrentUserData({ commit }) {
    return new Promise((resovle, reject) => {
      axiosInstance
        .get(apiPath.auth.getCurrentUser)
        .then((res) => {
          if (res.status == 200) {
            commit("doNothing");
            if(res?.data?.data?.images){
              commit("setCurrentUserImages", res.data.data.images);
            }
            if(res?.data?.data?.aadharVerified){
              commit("setAddharVerifed", res?.data?.data?.aadharVerified);
            }
            resovle(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  clearUserSession({ commit }) {
    console.log("clearUserSession called");
    commit("clearAll");
    document.cookie =
      "refreshToken; expires=Monday, 01 Jan 1970 00:00:00 UTC;";
    if(router.currentRoute?.name !== 'login-page') {
      return router.push({ name: "login-page" });  
    }
  },

  checkIsDuplicateMobileOrEmail(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.signUp.checkIsDuplicateMobileOrEmail, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  uploadImage(_, data) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("app_type", data.app_type);
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.signUp.uploadImage, formData)
        .then((res) => {
          if (res.data) {
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  checkIsDuplicateCompanyName(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.signUp.checkIsDuplicateCompanyName, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  signUpUser(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.signUp.signUpUser, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  verifyOTP(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.signUp.verifyOTP, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  resendOTP(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.signUp.resendOTP, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  reportIssue(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.auth.signUp.reportIssue, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
